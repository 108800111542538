import { toRaw } from 'vue'
import {
  collection,
  doc,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore'
import { signOut } from 'firebase/auth'
import { useTimeout } from '@vueuse/core'
// import { VueFirestoreDocumentData } from 'vuefire'

interface BRX_USER_OBJECT {
  brx_access: boolean, // This is the main access boolean for the BRX_USER
  exp_access: boolean, // This is the expermental feature access flag 
  is_admin: boolean,
  content: string
  createdAt: Timestamp
  displayName: string
  photoURL: string
  pos: number
  revision: number
}
// type brx_user = VueFirestoreDocumentData<BRX_USER_OBJECT>

const db = useFirestore()
const user = useCurrentUser()
const auth = useFirebaseAuth()!
const BRX_USER_DB = collection(db, 'BRX_USER_OBJECT')

// const CUR_USER = useCollection<BRX_USER_OBJECT>(
//   query(BRX_USER_DB, orderBy('createdAt', 'desc')),
//   {
//     ssrKey: 'BRX_USER_OBJECT',
//   }
// )

// const shallow = shallowRef(() => user.value && doc(BRX_USER_DB, user.value.uid))
// console.log(shallow.value)

async function get_current_user() {
  // Wait for the `useDocument` query to be loaded before proceeding
  while (pending.value && !error.value) {
    await new Promise((resolve) => setTimeout(resolve, 100))
  }
}


const CUR_USERRef = computed(() => user.value && doc(BRX_USER_DB, user.value.uid))

const {
  data: CUR_USER,
  pending,
  error
} = useDocument<BRX_USER_OBJECT>(CUR_USERRef)

//console.log(pending, error)

export default defineNuxtRouteMiddleware(async (to) => {
  const userwait = await getCurrentUser()
  const user = await getCurrentUser()

  await get_current_user() // Awaits the get_current_user function, to ensure the promise is resolved before proceeding
  // console.log("Inside the middleware")
  // console.log(BRX_USER_DB)
  // console.log(CUR_USER.value.brx_access) // CUR_USER.value should be defined by now, since we waited for the promise resolution
  
  if (!user) {
    return navigateTo({ path: '/login', query: { redirect: to.fullPath } })
  }else{
    if(!CUR_USER.value.is_admin){
      return navigateTo({ path: '/waitlist', query: { redirect: to.fullPath } })
    }
  }
})
  